// The Home JS
let Home = {

    // We need to store the colours
    content      : document.querySelector('.content'),
    news         : document.querySelector('.news'),
    newsContent  : document.querySelector('.news-content'),
    newsText     : document.querySelector('.news-text'),
    slider       : document.querySelector('.slider'),
    sliderHolder : document.querySelector('.slider-holder'),
    sliderArrow  : document.querySelector('.slider-arrow'),

    // Home Big and Small Logo
    homeLogoBig   : document.querySelector('.home .slider-logo'),
    homeLogoSmall : document.querySelector('.home .logo-link'),

    // Video Button and Iframe
    videoBtn     : document.querySelector('.video-button'),
    videoContent : document.querySelector('.video-intro-embed iframe'),
    videoHolder  : document.querySelector('.video-intro-embed'),

    // Init Function
    liftoff : () => {

        Home.initHomeSlide()
        Home.onScrollShowTheLogo()
        Home.onClickPlayTheVideo()
        //Home.moveSliderArrow()

        window.addEventListener('resize' , _.throttle(Home.marqueeNews , 500))
        window.addEventListener('resize' , Home.marqueeNews)
        document.addEventListener('DOMContentLoaded' , Home.marqueeNews)

    },

    // Make the news scroll
    marqueeNews : () => {

        let container = Math.ceil(Home.newsContent.clientWidth);
        let content   = Math.ceil(Home.newsText.clientWidth + 50);
        let diff      = container - content;

        // If difference is positive then it means we have enough space to show the text
        if (diff > 0)
            Home.news.classList.remove('marquee')

        if (diff < 0)
            Home.news.classList.add('marquee')


    },

    // Initialize the slider
    initHomeSlide : () => {

        if (!Home.slider) return;

        let flkty = new Flickity( Home.slider, {
            
            // options
            cellAlign            : 'center',
            contain              : true,
            draggable            : false,
            freeScroll           : false,
            prevNextButtons      : true,
            pageDots             : false,
            wrapAround           : true,
            setGallerySize       : true,
            autoPlay             : 3000,
            pauseAutoPlayOnHover : false,
            arrowShape           : "M30.9274933 6.65439833h20.769231L36.3452337 27.8763343c-5.1159364 7.1328671-9.911667 11.6820022-14.6080237 15.1637836L100 40.6919397v18.6161208l-78.26279-2.3481782c4.6963567 3.4854619 9.4920873 8.034597 14.6080237 15.1637836l15.3514906 21.2219358h-20.769231L0 50.0000001 30.9274933 6.65439833Z",

            // We need to attach a couple of events on the buttons
            on: {
                ready: function() {
                    
                    // We need to add events on the two buttons
                    this.nextButton.element.addEventListener('mousemove' , event => {
                        this.nextButton.element.firstElementChild.style.top  = `${event.layerY}px`
                        this.nextButton.element.firstElementChild.style.left = `${event.layerX}px`
                    })
                    this.prevButton.element.addEventListener('mousemove' , event => {
                        this.prevButton.element.firstElementChild.style.top  = `${event.layerY}px`
                        this.prevButton.element.firstElementChild.style.left = `${event.layerX}px`
                    })

                }
            }

        });


    },

    // Show/Hide the small logo when the big one is in and out of the viewport
    onScrollShowTheLogo : () => {

        // Observer option
        let options = {
            root       : null,
            rootMargin : '-50px',
            threshold  : [0,0.5,1]
        }

        let callback = (entries, observer) => {
            entries.forEach(entry => {

                // We're intersecting and 
                if (entry.isIntersecting) {

                    if (Home.homeLogoSmall.classList.contains('hidden'))
                        return;

                    Home.homeLogoSmall.classList.add('hidden');
                    return;

                }

                if (Home.homeLogoSmall.classList.contains('hidden'))
                    Home.homeLogoSmall.classList.remove('hidden')

                return;

            });
        };


        let observer = new IntersectionObserver(callback, options);

        observer.observe(Home.homeLogoBig);


    },

    onClickPlayTheVideo : () => {
        if (!Home.videoBtn) return;

        Home.videoBtn.addEventListener('click' , () => {

            let url = Home.videoContent.getAttribute('src');
                url = url.replace('autoplay=0' , 'autoplay=1')

            Home.videoContent.removeAttribute('hidden')
            Home.videoHolder.classList.toggle('is-open')
            Home.videoContent.setAttribute('src' , url)

        })

        Home.videoHolder.addEventListener('click' , (event) => {

            console.log(event)

            if (event.target != Home.videoHolder) return;

            let url = Home.videoContent.getAttribute('src');
                url = url.replace('autoplay=1' , 'autoplay=0')

            Home.videoContent.setAttribute('hidden' , true)
            Home.videoContent.setAttribute('src' , url)
            Home.videoHolder.classList.toggle('is-open')

        })
    }



}

// Launch The Script
Home.liftoff()
